import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
const Home: NextPage = () => {
  const router = useRouter();
  useEffect(() => {
    router.push('/start');
  });
  return <div data-sentry-component="Home" data-sentry-source-file="index.tsx">CCP Gaming.</div>;
};
export default Home;